import React from 'react';
import { useIntl, Link, injectIntl } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';
import Layout from '../layout/index';
import Footer from '../components/Footer/index';

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Helmet title={`${intl.formatMessage({ id: 'notfound.header' })}`} />
      <div className="masthead masthead-blog">
        <div className="masthead-contents">
          <div className="masthead-text">
            <h1 className="small">
              {intl.formatMessage({ id: 'notfound.header' })}
            </h1>
            <p className="sub-lead">
              {intl.formatMessage({ id: 'notfound.description' })}
            </p>
          </div>
        </div>
      </div>
      <div className="masthead-placeholder" />
      <div className="site-wrap">
        <div className="site-container site-container-404">
          <div className="site-content site-content-posts site-content-posts-large">
            <article>
              <blockquote>
                {intl.formatMessage({ id: 'notfound.lead' })}
              </blockquote>
              <p>{intl.formatMessage({ id: 'notfound.sublead' })}</p>
              <ol>
                <li>{intl.formatMessage({ id: 'notfound.list.try' })}</li>
                <li>{intl.formatMessage({ id: 'notfound.list.gotonav' })}</li>
                <li>
                  <Link to="/?utm_source=404_Error_Option">
                    {intl.formatMessage({ id: 'notfound.list.gotohome' })}
                  </Link>
                </li>
                <li>
                  <Link to="/support?utm_source=404_Error_Option">
                    {intl.formatMessage({ id: 'notfound.list.gotocst' })}
                  </Link>
                </li>
              </ol>
            </article>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default injectIntl(NotFoundPage);
